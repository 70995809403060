// src/data/flutterComponentsData.js
const flutterComponentsData = [
   /* {
      Component: "AMP ads",
      Status: "Used to embed AMP media ads",
      figmaLink: "",
    },
    */
    {
      Component: "Style Attributes",
      Details: "Border width, Border Radius, Elevation, Spacing, Text Styles, and Colours",
      Status: "Pending Updates",
      StatusType: "pending",
      figmaLink: "https://www.figma.com/design/WiGbilPYipJQdvp9gNXUY9/1---Variables-%26-Tokens?node-id=30-28&t=v3GiPoZiCX28NK9S-1",
    },
    {
      Component: "Text Button",
      Details: "Primary, Seconday, Tertiary, Inline Link, Disabled states",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/H4wxn4b3k775xWuk07aaac/1---AM-App-Pattern-Library?node-id=5814-12113&t=NaQ400Ok5uxfWYlF-4",
    },
    {
      Component: "Radio Button",
      Details: "Conditional Divider, Disabled states",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/H4wxn4b3k775xWuk07aaac/1---AM-App-Pattern-Library?node-id=6085-6754&t=NaQ400Ok5uxfWYlF-4",
    },
    {
      Component: "Checkbox",
      Details: "Conditional Divider, Disabled states",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/H4wxn4b3k775xWuk07aaac/1---AM-App-Pattern-Library?node-id=6085-6754&t=NaQ400Ok5uxfWYlF-4",
    },
    {
      Component: "Content Fragment",
      Details: "Vertical, Horizontal",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/H4wxn4b3k775xWuk07aaac/1---AM-App-Pattern-Library?node-id=6090-8015&t=NaQ400Ok5uxfWYlF-4",
    },
    {
      Component: "Floating Icon Button",
      Details: "Sticky Button",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/H4wxn4b3k775xWuk07aaac/1---AM-App-Pattern-Library?node-id=2236-5343&t=NaQ400Ok5uxfWYlF-4",
    },
    {
      Component: "Loading Spinner",
      Details: "Small, Large",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/H4wxn4b3k775xWuk07aaac/1---AM-App-Pattern-Library?node-id=7492-10771&t=NaQ400Ok5uxfWYlF-4",
    },
    {
      Component: "Dialog Cards",
      Details: "Various Styles",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/H4wxn4b3k775xWuk07aaac/1---AM-App-Pattern-Library?node-id=9019-8403&t=NaQ400Ok5uxfWYlF-4",
    },
    {
      Component: "Modal",
      Details: "Vertical button layout, Horizontal button layout",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/H4wxn4b3k775xWuk07aaac/1---AM-App-Pattern-Library?node-id=6085-6811&t=NaQ400Ok5uxfWYlF-4",
    },
    {
      Component: "App Bar",
      Details: "?",
      Status: "UX Review in progress",
      figmaLink: "",
    },
    {
      Component: "Styles",
      Details: "Attributes, Spacing, Text, Colours",
      Status: "UX Review in progress",
      figmaLink: "",
    },
    {
      Component: "Logo Containers",
      Details: "Air Miles, BMO, Partners, Different States",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/H4wxn4b3k775xWuk07aaac/1---AM-App-Pattern-Library?node-id=6090-9772&t=NaQ400Ok5uxfWYlF-4",
    },
    {
      Component: "Text Field",
      Details: "Various states, and validations",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/H4wxn4b3k775xWuk07aaac/1---AM-App-Pattern-Library?node-id=6002-6613&t=NaQ400Ok5uxfWYlF-4",
    },
    {
      Component: "Offer Mechanics Tag",
      Details: "Various tags",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/H4wxn4b3k775xWuk07aaac/1---AM-App-Pattern-Library?node-id=2015-5890&t=NaQ400Ok5uxfWYlF-4",
    },
    {
      Component: "Offer Card",
      Details: "Different Mechanics and items",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/H4wxn4b3k775xWuk07aaac/1---AM-App-Pattern-Library?node-id=2015-5972&t=NaQ400Ok5uxfWYlF-4",
    },
    {
      Component: "Curated Offer Card",
      Details: "Different Mechanics and items",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/H4wxn4b3k775xWuk07aaac/1---AM-App-Pattern-Library?node-id=2015-5972&t=NaQ400Ok5uxfWYlF-4",
    },
    {
      Component: "Shimmer effect",
      Details: "?",
      Status: "UX Review in progress",
      figmaLink: "",
    },
    {
      Component: "Bottom Sheet",
      Details: "options for orientation, icon, negative button, blurred background - scrollable or static",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/H4wxn4b3k775xWuk07aaac/1---AM-App-Pattern-Library?node-id=6085-6811&t=Qbd6gQjrBFqT9kvY-4",
    },
    {
      Component: "Confetti",
      Details: "-",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/H4wxn4b3k775xWuk07aaac/1---AM-App-Pattern-Library?node-id=6714-9010&t=Qbd6gQjrBFqT9kvY-4",
    },
    {
      Component: "Carousel",
      Details: "Various Offer Carousels",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/H4wxn4b3k775xWuk07aaac/1---AM-App-Pattern-Library?node-id=6714-7180&t=Qbd6gQjrBFqT9kvY-4",
    },
    {
      Component: "Hero Highlight",
      Details: "-",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/H4wxn4b3k775xWuk07aaac/1---AM-App-Pattern-Library?node-id=6712-7171&t=Qbd6gQjrBFqT9kvY-4",
    },
    {
      Component: "Content Container",
      Details: "Static and Interactive",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/H4wxn4b3k775xWuk07aaac/1---AM-App-Pattern-Library?node-id=6090-9763&t=Qbd6gQjrBFqT9kvY-4",
    },
    {
      Component: "SnackBar",
      Details: "Critical, Informational, Success, and Warning",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/H4wxn4b3k775xWuk07aaac/1---AM-App-Pattern-Library?node-id=5813-11698&t=Qbd6gQjrBFqT9kvY-4",
    },
    {
      Component: "Date Pickers",
      Details: "Old, New and New with required",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/H4wxn4b3k775xWuk07aaac/1---AM-App-Pattern-Library?node-id=5988-6630&t=Qbd6gQjrBFqT9kvY-4",
    },
    {
      Component: "PSA Card (Public Service Announcement)",
      Details: "Read Only, With Link (tappable)",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/H4wxn4b3k775xWuk07aaac/1---AM-App-Pattern-Library?node-id=5813-11672&t=Qbd6gQjrBFqT9kvY-4",
    },
    {
      Component: "Collector Tier Tag",
      Details: "Large, Medium, Small",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/H4wxn4b3k775xWuk07aaac/1---AM-App-Pattern-Library?node-id=3502-4774&t=Qbd6gQjrBFqT9kvY-4",
    },
    {
      Component: "Icons",
      Details: "Arrows, Brand, Contact, Indicators, Notifications, Other, User, Utilitiy, Composed - Midnight 700, White and Sapphire 900",
      Status: "UX Review in progress",
      figmaLink: "https://www.figma.com/design/WiGbilPYipJQdvp9gNXUY9/1---Colours%2C-Effects-%26-Typography?node-id=2382-2288",
    },
];

export default flutterComponentsData;
