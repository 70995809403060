// src/pages/AppTokens.js
import React, { useState } from "react";

import AppTokensColours from "./AppTokensColours";
import AppTokensBorderWidth from "./AppTokensBorderWidth";
import AppTokensBorderRadius from "./AppTokensBorderRadius";
import AppTokensShadows from "./AppTokensShadows";
import AppTokensSpacing from "./AppTokensSpacing";

import TitleBar from "../../components/TitleBar";

const Tokens = () => {
  const [activeTab, setActiveTab] = useState("Colours");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Colours":
        return <AppTokensColours />;
      case "Border Width":
        return <AppTokensBorderWidth />;
      case "Border Radius":
          return <AppTokensBorderRadius />;
      case "Shadows":
          return <AppTokensShadows />; 
      case "Spacing":
          return <AppTokensSpacing />;     
      default:
        return null;
    }
  };

  const titleBarData = {
    eyebrowText: "",
    pageTitle: "Tokens",
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large">Tokens are used for the compostion of our components. They ensure consistent variables are cascaded across patterns and layouts.</p>

      <div className="spacer level-2"></div>

      <div className="tabsContainer">
        <nav className="tabs">
          <ul>
            <li
              className={activeTab === "Colours" ? "active" : ""}
              onClick={() => handleTabClick("Colours")}
            >
              Colours
            </li>
            <li
              className={activeTab === "Border Width" ? "active" : ""}
              onClick={() => handleTabClick("Border Width")}
            >
              Border Width
            </li>
            <li
              className={activeTab === "Border Radius" ? "active" : ""}
              onClick={() => handleTabClick("Border Radius")}
            >
              Border Radius
            </li>
            <li
              className={activeTab === "Shadows" ? "active" : ""}
              onClick={() => handleTabClick("Shadows")}
            >
              Shadows
            </li>
            <li
              className={activeTab === "Spacing" ? "active" : ""}
              onClick={() => handleTabClick("Spacing")}
            >
              Spacing
            </li>
          </ul>
        </nav>
      </div>
      
      {renderTabContent()}

    </>
  );
};

export default Tokens;
