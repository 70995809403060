// src/data/appTokenData.js
const borderRadiusTokenData = [
    {
        "token": "--am-border-radius-0",
        "value": "0px"
    },
    {
        "token": "--am-border-radius-1x",
        "value": "4px"
    },
    {
        "token": "--am-border-radius-2x",
        "value": "8px"
    },
    {
        "token": "--am-border-radius-3x",
        "value": "12px"
    },
    {
        "token": "--am-border-radius-4x",
        "value": "16px"
    },
    {
        "token": "--am-border-radius-10x",
        "value": "40px"
    },
    {
        "token": "--am-border-radius-full",
        "value": "100px"
    },
];

const borderWidthTokenData = [
    {
        "token": "--am-base-border-width-0.5x",
        "value": "0.5px"
    },
    {
        "token": "--am-base-border-width-1x",
        "value": "1px"
    },
    {
        "token": "--am-base-border-width-2x",
        "value": "2px"
    },
];

const shadowTokenData = [
    {
        token: "--am-base-shadow-1",
        value: "0px 2px 2px 0px rgba(40, 47, 64, 0.1)"
    },
    {
        token: "--am-base-shadow-2",
        value: "0px 4px 4px 0px rgba(40, 47, 64, 0.1)"
    },
    {
        token: "--am-base-shadow-3",
        value: "0px 8px 8px 0px rgba(40, 47, 64, 0.1)"
    },
];

const spacingTokenData = [
    {
        token: "--am-base-spacing-0",
        value: "0px"
    },
    {
        token: "--am-base-spacing-0.5",
        value: "4px"
    },
    {
        token: "--am-base-spacing-1x",
        value: "8px"
    },
    {
        token: "--am-base-spacing-2x",
        value: "16px"
    },
    {
        token: "--am-base-spacing-3x",
        value: "24px"
    },
    {
        token: "--am-base-spacing-4x",
        value: "32px"
    },
    {
        token: "--am-base-spacing-5x",
        value: "40px"
    },
    {
        token: "--am-base-spacing-6x",
        value: "48px"
    },
    {
        token: "--am-base-spacing-7x",
        value: "56px"
    },
    {
        token: "--am-base-spacing-8x",
        value: "64px"
    },
    {
        token: "--am-base-spacing-9x",
        value: "72px"
    },
    {
        token: "--am-base-spacing-10x",
        value: "80px"
    },
];

export { borderRadiusTokenData, borderWidthTokenData, shadowTokenData, spacingTokenData };
