// src/pages/AppLayouts.js
import React, { useState } from "react";

import SubNav from "../../components/SubNav";
import TitleBar from "../../components/TitleBar";

const Layouts = () => {

  const titleBarData = {
    eyebrowText: "Foundation",
    pageTitle: "Layout",
  };

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };
  
  const [activeSection, setActiveSection] = useState(null);
  
  const sections = [
    { id: 'overview', label: 'Overview' },
    { id: 'layout-types', label: 'Layout Types' },
    { id: 'transitions', label: 'Transitions' },
    { id: 'navigation-behaviour', label: 'Navigation Behaviour' },
  ];

  return (
    <>
      <div id="overview"></div>
      <TitleBar {...titleBarData} />
      <p className="large">In app, our layouts are composed of various templates and template types. The behaviour when navigating to each layout is dependent on the OS, as well as the layout type.</p>
    
      <div className="columns reverse">
        <main className="main">

          <h2 className="title-level-2 padding-level-2 anchor" id="layout-types">Layout Types</h2>

          <h3 className="title-level-3 padding-level-2">Landing Pages</h3>

          <div className="columns">
            <div className="col one_third">
              <img src={process.env.PUBLIC_URL + '/images/layout/landing-1.jpg'} alt="" className="border" />
              <div className="spacer level-1"></div>
              <p className="small">Home</p>
            </div>
            <div className="col one_third">
              <img src={process.env.PUBLIC_URL + '/images/layout/landing-2.jpg'} alt="" className="border" />
              <div className="spacer level-1"></div>
              <p className="small">Offers</p>
            </div>
            <div className="col one_third">
              <img src={process.env.PUBLIC_URL + '/images/layout/landing-3.jpg'} alt="" className="border" />
              <div className="spacer level-1"></div>
              <p className="small">Transactions</p>
            </div>
          </div>

          <div className="spacer level-2"></div>

          <h3 className="title-level-3 padding-level-2">Modals</h3>

          <div className="columns">
            <div className="col one_third">
              <img src={process.env.PUBLIC_URL + '/images/layout/modal-1.jpg'} alt="" className="border" />
              <div className="spacer level-1"></div>
              <p className="small">My Card</p>
            </div>
            <div className="col one_third">
              <img src={process.env.PUBLIC_URL + '/images/layout/modal-2.jpg'} alt="" className="border" />
              <div className="spacer level-1"></div>
              <p className="small">Air Miles Receipts</p>
            </div>
            <div className="col one_third">
              <img src={process.env.PUBLIC_URL + '/images/layout/modal-3.jpg'} alt="" className="border" />
              <div className="spacer level-1"></div>
              <p className="small">Notifications</p>
            </div>
          </div>

          <div className="spacer level-2"></div>

          <h3 className="title-level-3 padding-level-2">Deep Links</h3>

          <div className="columns flex-start">
            <div className="col one_third">
              <img src={process.env.PUBLIC_URL + '/images/layout/deep-link-1.jpg'} alt="" className="border" />
              <div className="spacer level-1"></div>
              <p className="small">Offer Details</p>
            </div>
            <div className="col one_third">
              <img src={process.env.PUBLIC_URL + '/images/layout/deep-link-2.jpg'} alt="" className="border" />
              <div className="spacer level-1"></div>
              <p className="small">Partner Offers</p>
            </div>
          </div>

          <div className="spacer level-2"></div>

          <h2 className="title-level-2 padding-level-2 anchor" id="transitions">Transitions</h2>

          <div className="callout">
            <p className="padding-level-1"><strong>Regular Navigation</strong></p>
            <p>Regular Navigation transitions are used when a user transitions between sections of the app (ie. Home screen to Offers to Account, etc.). Transitions between these sections should use the default transition as defined by the OS - <strong>No additional development is required</strong>.</p>
            <p>For example IOs transitions by covering the screen from the right while Android opens from center.</p>

            <p className="padding-level-1"><strong>Fullscreen Presentation</strong></p>
            <p>Fullscreen Presentation are used to cover the whole screen (including the tab bar). These are currently used for Notifications, My Card and a few other experiences. Fullscreen Presentation transitions and behviour should use the default transition as defined by the OS - <strong>No additional development is required</strong>.</p>
            <p>For example, IOs Fullscreen Presentation come from the bottom and have an "x" in the top right corner to close, while Android Fullscreen Presentation come in from the right and have a back arrow to close.</p>
            <hr />
            <p><em>Unless explained otherwise, the transition effects (fade in, slide in, expand etc.) between screens and flows by default should be defined by the Operating System. - Developers do not need to add any additional definitions.</em></p>
          </div>

          <div className="spacer level-2"></div>

          <h2 className="title-level-2 padding-level-2 anchor" id="navigation-behaviour">Navigation Behaviour</h2>

          <h3 className="title-level-3 padding-level-2">Navigation Bar</h3>

          <div className="columns">
            <div className="col one_half">
              <img src={process.env.PUBLIC_URL + '/images/layout/navigation-bar-sapphire.png'} alt="" className="border" />
              <div className="spacer level-1"></div>
              <p className="small"><em>Sapphire 100 Navigation Bar (usage TBD)</em></p>

              <div className="spacer level-2"></div>
            </div>
            <div className="col one_half">
              <img src={process.env.PUBLIC_URL + '/images/layout/navigation-bar-white.png'} alt="" className="border" />
              <div className="spacer level-1"></div>
              <p className="small"><em>White Navigation Bar (usage TBD)</em></p>

              <div className="spacer level-2"></div>
            </div>
          </div>
          <p>According to <a href="https://m3.material.io/components/navigation-bar/overview" target="_blank" rel="noreferrer">Material Design's pattern library</a>, the bottom navigation bar is called "Navigation Bar", while it is called a "Tab Bar" in <a href="https://developer.apple.com/design/human-interface-guidelines/tab-bars" target="_blank" rel="noreferrer">Apple's iOS pattern library</a>. For clarity (and to reflect the pattern's usage) we call this pattern "Navigation Bar" in our design system.</p>
          <p>The default behaviour of the Navigation bar varies between operating systems. To provide a consistent user experience we have implemented the same behaviour for both operating systems:</p>
          <ul>
            <li>On inital tap of a navigation item take the user to the landing page of that section (ie. Offers).</li>
            <li>When a user navigates via a deep-link (ie. taps on an offer) - Keep the navigation link in its active state.</li>
            <li>When tapping the navigation link when on a page accessed through a deep link, take the user back to parent page (ie. Offers)</li>
          </ul>

          <div className="callout">
            <p className="padding-level-1"><strong>Navigation Bar Visibility</strong></p>
            <p>The Navigation Bar should always be present (unless a modal is open - i.e. when "My Card" modal is opened, it should appear full screen and cover the Navigation Bar). This includes landing pages, and deep links. For Example, if on "Offers" landing screen, or on an offer details screen, the Navigation Bar should be present.</p>
          </div>

        </main>

        <SubNav sections={sections} activeSection={activeSection} onSectionClick={handleSectionClick} />
      </div>
    </>
  );
};

export default Layouts;
