// src/pages/tutorials/AEMTips.js
import React, { useState } from "react";

import SubNav from "../../components/SubNav";
import TitleBar from "../../components/TitleBar";

const AEMTips = () => {

  const [activeSection, setActiveSection] = useState("overview");

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  const sections = [
    { id: 'teaserComponent', label: 'Teaser Component' },
    { id: 'quickLinks', label: 'Quick Links' },
    { id: 'bodyTextStyles', label: 'Body Text Styles' },
    { id: 'titleOverrides', label: 'Title Overrides' },
    { id: 'containerStyles', label: 'Container Styles' },
  ];

  const titleBarData = {
    eyebrowText: "Tutorials",
    pageTitle: "AEM Component Tips & Tricks",
  };

  return (
    <>
      <div id="overview"></div>
      <TitleBar {...titleBarData} />
      <div className="columns reverse relative">
        <main className="main">
          <p className="large">Some components and layout elements require custom configurations. Here are some tips &amp; tricks on how to configure those components.</p>
        
          <h2 id="teaserComponent" className="title-level-2 padding-level-1 anchor">Teaser Component</h2>

          <p>The Teaser component is a card component where the whole component is clickable. The link is set under the "Links" tab when you open the component settings. It has 6 areas that can be independently configured:</p>

          <div className="columns">
            <div className="col one_third">
              <ol>
                <li>Image</li>
                <li>Icon</li>
                <li>Logo</li>
                <li>Tag</li>
                <li>Title</li>
                <li>Details</li>
              </ol>
            </div>
            <div className="col two_third">
              <img src={process.env.PUBLIC_URL + '/images/tutorials/teaser-markup.png'} alt="" />
            </div>
          </div>

          <div className="spacer level-4"></div>
          
          <div className="numberGroup small">
            <div className="col">
              <span className="number">1</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Image</h2>
              <p>The image portion of the teaser component is added in the configuration panel under "Asset". The image's width is 100% of the teaser component with auto (relative) height. Alternative text should be included for images that are <strong>not</strong> decorative. When adding multiple teaser components in a row, it is important that all images are the same size to ensure consistent heights.</p>
              <img src={process.env.PUBLIC_URL + '/images/tutorials/teaser-image.png'} className="border" alt="" />
            </div>
          </div>

          <div className="spacer level-4"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">2</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Icon</h2>
              <p>The icon is set under "Image" tab and is <strong>optional</strong>. Make sure to include alt text if the icon is not decorative.</p>
              <img src={process.env.PUBLIC_URL + '/images/tutorials/teaser-icon.png'} className="border" alt="" />
            </div>
          </div>

          <div className="spacer level-4"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">3</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Logo</h2>
              <p>The logo is set under "Image" tab and is <strong>optional</strong>. Make sure to inlcude alt text.</p>
              <img src={process.env.PUBLIC_URL + '/images/tutorials/teaser-logo.png'} className="border" alt="" />
            </div>
          </div>

          <div className="spacer level-4"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">4</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Tag</h2>
              <p>The tag is set under "Text" tab and is <strong>optional</strong>. Tags can be selected from a pre existing list. Click the checkmark to open the tag browser and choose the desired tag to apply.</p>
              <div className="columns">
                <div className="col one_half">
                  <img src={process.env.PUBLIC_URL + '/images/tutorials/teaser-tag-1.png'} className="border" alt="" />
                </div>
                <div className="col one_half">
                <img src={process.env.PUBLIC_URL + '/images/tutorials/teaser-tag-2.png'} className="border" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="spacer level-4"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">5</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Title</h2>
              <p>The title is added under the "text" tab. Every teaser should include a title.</p>
              <img src={process.env.PUBLIC_URL + '/images/tutorials/teaser-title.png'} className="border" alt="" />
            </div>
          </div>

          <div className="spacer level-4"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">6</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Description</h2>
              <p>The Description area uses a WYSIWYG editor. The different text styles are applied with the options in the WYSIWYG editor. To access these tools, you need to expand the setting panel to full screen by clicking the "full screen" icon at the top of the modal.</p>
              <img src={process.env.PUBLIC_URL + '/images/tutorials/teaser-full-screen.png'} className="border" alt="" />
            
              <div className="spacer level-2"></div>

              <h3 className="title-level-3-large padding-level-2">Description Styles</h3>
              <div className="columns">
                <div className="col one_third">
                  <ol>
                    <li>Main text</li>
                    <li>Legal text</li>
                    <li>Highlight text</li>
                  </ol>
                </div>
                <div className="col two_third">
                  <img src={process.env.PUBLIC_URL + '/images/tutorials/teaser-copy-markup.png'} alt="" />
                </div>
              </div>

              <div className="spacer level-2"></div>

              <p>To style text as either "Legal text" or "Highlight text", select the text in the WYSIWYG editor, click the "S" icon, and choose the style from the drop down menu.</p>
            
              <div className="columns">
                <div className="col one_half">
                  <img src={process.env.PUBLIC_URL + '/images/tutorials/teaser-legal.png'} className="border" alt="" />
                  <div className="spacer level-1"></div>
                  <p><em>Legal text</em></p>
                </div>
                <div className="col one_half">
                  <img src={process.env.PUBLIC_URL + '/images/tutorials/teaser-highlight.png'} className="border" alt="" />
                  <div className="spacer level-1"></div>
                  <p><em>Highlight text</em></p>
                </div>
              </div>
            </div>
          </div>

          <div className="rule"></div>

          <h2 id="quickLinks" className="title-level-2 padding-level-2 anchor">Quick Links</h2>
          
          <p>The "Quick Link" component is a variant of the <em>teaser component</em>. The whole component is clickable. The link is set under the "Links" tab when you open the component settings. It has 4 areas that can be independently configured:</p>

          <div className="columns">
            <div className="col one_third">
              <ol>
                <li>Icon</li>
                <li>Title</li>
                <li>Link Type</li>
                <li>Description</li>
              </ol>
            </div>
            <div className="col two_third">
              <img src={process.env.PUBLIC_URL + '/images/tutorials/quicklink-anatomy.png'} alt="" />
            </div>
          </div>

          <div className="spacer level-4"></div>

          <div className="columns">
            <div className="col one_half">
              <h2 className="title-level-2-small padding-level-2">Choosing Quick Link variant</h2>
              <p>When you add a teaser component, you can change the component to appear as a quick link by choosing the Teaser type as "Quick Links" under the "Styles" tab.</p>
            </div>
            <div className="col one_half">
              <img src={process.env.PUBLIC_URL + '/images/tutorials/quicklink-style.png'} className="border" alt="" />
            </div>
          </div>

          <div className="spacer level-2"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">1</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Icon</h2>
              <p>The icon is set under "Image" tab.  Make sure to include alt text if the icon is not decorative.</p>
              <img src={process.env.PUBLIC_URL + '/images/tutorials/quicklink-icon.png'} className="border" alt="" />
            </div>
          </div>

          <div className="spacer level-4"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">2</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Title</h2>
              <p>The title is added under the "text" tab. Every quicklink should include a title.</p>
              <img src={process.env.PUBLIC_URL + '/images/tutorials/quicklink-title.png'} className="border" alt="" />
            </div>
          </div>

          <div className="spacer level-4"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">3</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Link Type</h2>
              <p>When a link is added to the Quick Link component, it will automatically add a right arrow. If the Quick Link is meant to link to an external page, you need to check the box beside "Is it an external link" - this will change the arrow to an external link icon.</p>
              <img src={process.env.PUBLIC_URL + '/images/tutorials/quicklink-arrow.png'} className="border" alt="" />
            </div>
          </div>
          
          <div className="spacer level-4"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">4</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Description</h2>
              <p>The Description is added under the "text" tab. The Description is optional.</p>
              <img src={process.env.PUBLIC_URL + '/images/tutorials/quicklink-description.png'} className="border" alt="" />
            </div>
          </div>

          <div className="spacer level-1"></div>

          <div className="rule"></div>

          <h2 id="bodyTextStyles" className="title-level-2 padding-level-2 anchor">Body Text Styles</h2>
          <p>Body text has a large variety of styles available for <strong>size, colour, bottom padding, screen reader text, text styles and special treatments</strong>. These settings are available when the component settings panel is expanded.</p>

          <p>In order to set custom sizes to body text, you must first click on the component followed by clicking the settings icon.</p>

          <img src={process.env.PUBLIC_URL + '/images/tutorials/text-settings-select.png'} className="border" alt="" />

          <div className="spacer level-1"></div>

          <p>After clicking the settings icon, the settings modal will open. From here, click the "full screen" icon to get access to all settings.</p>

          <img src={process.env.PUBLIC_URL + '/images/tutorials/text-settings.png'} className="border" alt="" />

          <div className="spacer level-4"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">1</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Size</h2>
              <p>Under the "Styles" tab, font size options can be selected. If no size is selected, the body text defaults to our standard body text size. Size options include: XSmall, Small and Large. In addition, this is where "Eyebrow Text" can be selected (standard and slim options). Please refer to the figma files for which sizing option should be selected.</p>
              <img src={process.env.PUBLIC_URL + '/images/tutorials/text-font-size.png'} className="border" alt="" />
            </div>
          </div>

          <div className="spacer level-4"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">2</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Colour</h2>
              <p>Under the "Styles" tab, font colour options can be selected. If no colour is selected, the body text defaults to our standard body colour (based on the section or container background it is on). Colour options include: Hot Pink 500, Lavender 400, Electric Lime 500, Gold 900, Onyx 900 and Sapphire 900. Please refer to the figma files for which colour option should be selected.</p>
              <img src={process.env.PUBLIC_URL + '/images/tutorials/text-colour.png'} className="border" alt="" />
            </div>
          </div>

          <div className="spacer level-4"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">3</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Padding (Bottom)</h2>
              <p>Paragraph text by default includes bottom padding (24px) to ensure consistent space between paragraphs. In some designs, this padding needs to be reduced or removed completely. This can be done under the "Styles" tab by selecting an option under "Bottom Padding Override".</p>
              <img src={process.env.PUBLIC_URL + '/images/tutorials/text-padding.png'} className="border" alt="" />
            </div>
          </div>

          <div className="spacer level-4"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">4</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Text Styles</h2>
              <p>For SEO it is sometimes required to have text that looks like a heading, but does not use the HTML Heading tag. Under the "Styles" tab there is an option under the "Styles" tab to make body text like a "Heading 2" style. An example of this can be found on the airmiles.ca homepage. To avoid having repeat headings in the carouel, the text used for headings use this setting.</p>
              <img src={process.env.PUBLIC_URL + '/images/tutorials/text-styles.png'} className="border" alt="" />
            </div>
          </div>

          <div className="spacer level-4"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">5</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Screen Reader text</h2>
              <p>Our text component can be used for "screen reader" copy. Under "Styles" there is an optoon under the "Screen Reader Only" section to hide text from the layout, but keep it available for screen readers to pickup.</p>
              <img src={process.env.PUBLIC_URL + '/images/tutorials/text-screen-reader.png'} className="border" alt="" />
            </div>
          </div>

          <div className="spacer level-4"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">6</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Special Treatments</h2>
              <p>In the text WYSIWYG editor there is an "S" icon that allows for selected text to have special styles. This icon is only visible when the settings modal is opened in "Full Screen" mode. These special styles are used for:</p>
              <ul>
                <li>Notification text with icons (used with the "in-page alert" component).
                    <ul>
                      <li>Error</li>
                      <li>Info</li>
                      <li>Warning</li>
                      <li>Success</li>
                    </ul>
                </li>
                <li>Blockquote text (text with a decorative quote icon)</li>
                <li><strike>CTA Right Chevron</strike></li>
              </ul>
              <img src={process.env.PUBLIC_URL + '/images/tutorials/text-special-styles.png'} className="border" alt="" />
            </div>
          </div>

          <div className="spacer level-1"></div>

          <div className="rule"></div>

          <h2 id="titleOverrides" className="title-level-2 padding-level-2 anchor">Title Overrides</h2>
          <p>The title component is used for Headings that use the HTML H markup. With this component you can choose any heading, as well style overrides including: <strong>Size, Spacing, Colour, Text Alignment, Screen Reader text, and custom Title Styles</strong>.</p>
          <p>The first thing that needs to be done when using the title component is to open the setting panel and select the title "type". Refer to the Figma design to see what heading type should be selected.</p>
          <img src={process.env.PUBLIC_URL + '/images/tutorials/title-settings.png'} className="border" alt="" />

          <div className="spacer level-4"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">1</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Size</h2>
              <p>Under the "Styles" tab, there is a menu for size. Size refers to an override to the default heading sizes. For each heading type (H1, H2, etc), there are additional size options. Refer to the Figma designs to see which option should be selected.</p>
              <img src={process.env.PUBLIC_URL + '/images/tutorials/title-size.png'} className="border" alt="" />
            </div>
          </div>

          <div className="spacer level-4"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">2</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Spacing</h2>
              <p>Under the "Styles" tab, there is a menu for spacing. Spacing refers to an override to the default heading bottom margin. In most cases the default size is the correct one, but please refer to the Figma design to see if different sizing options are selected.</p>              
              <img src={process.env.PUBLIC_URL + '/images/tutorials/title-spacing.png'} className="border" alt="" />
            </div>
          </div>

          <div className="spacer level-4"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">3</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Colour</h2>
              <p>Under the "Styles" tab, there is a menu for colour overrides. Refer to the Figma design to identify colour overrides.</p>
              <img src={process.env.PUBLIC_URL + '/images/tutorials/title-colour.png'} className="border" alt="" />
            </div>
          </div>

          <div className="spacer level-4"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">4</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Text Alignment</h2>
              <p>Under the "Styles" tab, there is a menu for text alignment. While our containers have alignment options, in order to align heading text (left, center or right), it needs to be done by choosing one of these menu items.</p>
              <img src={process.env.PUBLIC_URL + '/images/tutorials/title-alignment.png'} className="border" alt="" />
            </div>
          </div>

          <div className="spacer level-4"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">5</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Screen Reader Text</h2>
              <p>Under the "Styles" tab, there is a menu to "Screen Reader Only". This setting allows you to hide heading text from the layout, but keep it available for screen readers to pickup.</p>
              <img src={process.env.PUBLIC_URL + '/images/tutorials/title-screen-reader.png'} className="border" alt="" />
            </div>
          </div>

          <div className="spacer level-4"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">4</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Custom Title Styles</h2>
              <p>Under the "Styles" tab, there is a menu for custom styles. Currently there is only one option for "Eyebrow" text. This is used for pages that need to have an H1 that needs to be literal for SEO (ie. About the Air Miles Program), but is treated as a subheading over a heading that is more "aspirational" (ie. Collecting Moments).</p>
              <img src={process.env.PUBLIC_URL + '/images/tutorials/title-style.png'} className="border" alt="" />
            </div>
          </div>

          <div className="spacer level-1"></div>

          <div className="rule"></div>

          <h2 id="containerStyles" className="title-level-2 padding-level-2 anchor">Container Styles</h2>
          <p>The container component is the most robust component that is used for a variety of patterns. These include: <strong>Inner container, In-Page Alerts, Icon Text and Button Group, Table Container, and Modals</strong>.</p>
          
          <div className="spacer level-2"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">1</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Inner Container</h2>
              <p>Almost all content on our pages needs to be set inside of an inner container. This is achievable by adding a container with "Container Type" set to "Inner Container. The Inner Container has a max-width of 1140px and is horizotnally centered. Additional containers can be added inside of this container.</p>
            </div>
          </div>

          <img src={process.env.PUBLIC_URL + '/images/tutorials/inner-container.png'} className="border" alt="" />

          <div className="spacer level-2"></div>

          <img src={process.env.PUBLIC_URL + '/images/tutorials/container-type.png'} className="border" alt="" />

          <div className="spacer level-4"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">2</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">In-Page Alerts</h2>
              <p>For Info, Error, Warning and Success "In-Page Alert" containers, these can be set by choosing the according "Container Type". To add the correct icon next to the title, the text component inside the container needs to have the custom text style applied (see "Body Text Style" - "6. Special Treatments" above)</p>
            </div>
          </div>

          <img src={process.env.PUBLIC_URL + '/images/tutorials/in-page-alerts.png'} alt="" />

          <div className="spacer level-2"></div>

          <img src={process.env.PUBLIC_URL + '/images/tutorials/container-type.png'} className="border" alt="" />

          <div className="spacer level-4"></div>

          <div className="numberGroup small">
            <div className="col">
              <span className="number">3</span>
            </div>
            <div className="col">
              <h2 className="title-level-2-small padding-level-2">Icon Text &amp; Button Group</h2>
              <p>The "Icon Text Group" and "Button Group" Container type allow for content inside to ingnore our 12 column grid and set a specific px gap. For "Icon Text Group", the gap is 16x and is intended to be used with an image component (with one of our available icons - svg file) and text. In some cases, the "Icon Text Group Nowrap" option needs to be used for long text in a small column. The "Button Group" Container Type is used when you want two buttons side by side. This container has a gap of 24px. In order to use either of these containers, in addition to setting the container type, you need to set the "Layout" under the "Properties" tab to "Simple". There is also a setting for "Button Group Alignment" - This setting will be updated to "Button and Icon Text Group Alignment" in the near future. This is used to horizontall align buttons on the page.</p>
            </div>
          </div>

          <img src={process.env.PUBLIC_URL + '/images/tutorials/group-containers.png'} alt="" />

          <div className="spacer level-2"></div>

          <img src={process.env.PUBLIC_URL + '/images/tutorials/container-type.png'} className="border" alt="" />

          <div className="spacer level-2"></div>

          <img src={process.env.PUBLIC_URL + '/images/tutorials/button-group-alignment.png'} className="border" alt="" />
        
          <div className="spacer level-1"></div>

        </main>
      
        <SubNav sections={sections} activeSection={activeSection} onSectionClick={handleSectionClick} />
      </div>
    </>
  );
};

export default AEMTips;