// src/pages/CreativeAssets.js
import React from "react";

import TitleBar from "../../components/TitleBar";

const CreativeAssets = () => {

  const titleBarData = {
    eyebrowText: "Resources",
    pageTitle: "Creative Assets",
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large">AIR MILES has a full suite of creative assets, including our outstanding illustrations, branded PowerPoint template, logos, fonts, and more. <a href="https://loyaltyone-my.sharepoint.com/:f:/g/personal/jrice_loyalty_com/Egf_eUgCrxtNte2vGgU6jxcBGpW7hDd1b5lB1VRkjLEoFw" target="_blank" rel="noreferrer">Explore the library of assets here</a>.</p>

      <div className="spacer level-2"></div>

      <h2 className="title-level-2 padding-level-2">Logo Assets</h2>

      <img src={process.env.PUBLIC_URL + '/images/logo-full.svg'} alt="" style={{width:240}} /><br />

      <p>To access vector versions of the Air Miles logo in both English and French, follow the link below.</p>


      <a href="https://loyaltyone.sharepoint.com/sites/MET/_layouts/15/guestaccess.aspx?share=Envo6lmivDZOrZe6S9_AfEABQnSDxMyV1vE6hpmkvVeN6g&e=cWbL97" className="btn" target="_blank" rel="noreferrer">Download Logo Files</a>

    </>
  );
};

export default CreativeAssets;